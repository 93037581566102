import react, { useState } from 'react'
import { createRoot } from 'react-dom'
import data from '../cidades-estados.json'

function getCities(state) {
    const estado = data.estados.find(estado => estado.sigla == state)
    return estado.cidades
}

function getStates() {
    return data.estados.map(estado => {
        return {
            nome: estado.nome,
            sigla: estado.sigla
        }
    })
}

function Inputs() {
    const [cities, setCities] = useState([''])
    const states = getStates()

    function handleChange(state) {
        const cidades = getCities(state)
        setCities(cidades)
    }

    return(
        <>
            <select onChange={event => handleChange(event.target.value)} name='estado' id="estado" required>
                <option value="" selected disabled hidden>Selecione um estado...</option>
                {states.map(state => (
                    <option value={state.sigla}>{state.nome}</option>
                ))}
            </select>
            <select name='cidade' id="cidade" required>
                <option value="" selected disabled hidden>Selecione uma cidade...</option>
                {!!cities && cities.map(city => (
                    <option value={city} key={city}>{city}</option>
                ))}
            </select>
        </>
    )
}

const stateCityInputContainer = document.querySelector('#stateCityInputContainer')
const root = createRoot(stateCityInputContainer)
root.render(<Inputs />)